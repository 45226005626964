import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AuthorBlock = makeShortcode("AuthorBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AuthorBlock title="Rachel Caldwell" subtitle="Director" mdxType="AuthorBlock">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "648px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/aab8fa1fe87bdae2c110628bd148f522/6e52e/01.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "150%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEA//EABcBAQEBAQAAAAAAAAAAAAAAAAAEAgP/2gAMAwEAAhADEAAAAYnzqx0xEB95q655CsP/xAAbEAACAgMBAAAAAAAAAAAAAAABAgAREiEiA//aAAgBAQABBQI6jKympfXseMooslbDLRc9ZasT/8QAGREAAgMBAAAAAAAAAAAAAAAAAAEQESGB/9oACAEDAQE/AYTrDp//xAAaEQACAgMAAAAAAAAAAAAAAAAAAQIQESFR/9oACAECAQE/AalFPDRrh//EABwQAAEDBQAAAAAAAAAAAAAAAAABAhARITHB4f/aAAgBAQAGPwIo9IQ7O4apYwf/xAAcEAEAAwEBAAMAAAAAAAAAAAABACExEVFBgZH/2gAIAQEAAT8ho9fiDw8cTI+Q5Nr7Ogb2tTolLiZHvCnEekqIEfqX7H7Htfc//9oADAMBAAIAAwAAABCIyoL/xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQMBAT8Qy2UKZHZ//8QAGREAAwADAAAAAAAAAAAAAAAAAAERITGh/9oACAECAQE/ECMaoQWGvD//xAAfEAEAAgICAgMAAAAAAAAAAAABABEhQTFRYXGR0fH/2gAIAQEAAT8Qwt9FNrgnAyGWvT3DHMpi8RA40A88w1hobOTzXqBAHWYNY5irqAgTUoU2Ys+ZXrkWNfUQEFJ0x+xq1lLDQEh1Stqz/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "01",
              "title": "01",
              "src": "/static/aab8fa1fe87bdae2c110628bd148f522/6e52e/01.jpg",
              "srcSet": ["/static/aab8fa1fe87bdae2c110628bd148f522/f93b5/01.jpg 300w", "/static/aab8fa1fe87bdae2c110628bd148f522/b4294/01.jpg 600w", "/static/aab8fa1fe87bdae2c110628bd148f522/6e52e/01.jpg 648w"],
              "sizes": "(max-width: 648px) 100vw, 648px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </AuthorBlock>
    <blockquote>
      <p parentName="blockquote">{`Pechersk School International‘s 25th Anniversary year is one we will recall with pride. This is true, initially, for our many achievements and successes, in the context of the COVID-19 crisis.`}</p>
    </blockquote>
    <p>{`The school’s educational team rose to the many challenges created by the pandemic situation. I was left speechless by the commitment of our faculty, teachers and leaders, and by their readiness to significantly adapt their practices to meet our students’ needs. PSI’s students are also remarkable; resilient, courageous and caring. Everyone has remained determined, collaborative and supportive throughout this period. Indeed, our whole community has shown its tireless dedication to PSI.`}</p>
    <p>{`As part of PSI’s 25th Anniversary celebration, PSI  would like to honour the beginning of this truly unique story and recognise some of the key achievements along the way.`}</p>
    <p>{`The story began in 1995, when a group of expatriate families living in Kyiv established PSI as a private, not for profit, international school with around 60 students. Since then, we have grown steadily to 510 students from 50 nationalities, with 70% of the student population from the expatriate diplomatic, government and business communities. Eight directors have served the school. Between 1998 and 2000, PSI received authorised status to offer three IB Programmes: PYP, MYP and DP: a huge achievement!.  PSI continues to be the only school in Ukraine to offer these three internationally renowned programmes.`}</p>
    <p>{`In 2003, PSI moved to new premises; the current school campus. It was also accredited by The New England Association of Schools and Colleges (NAESC) in the Untied States and The Council of international Schools (CIS) in Europe. We were re-authorised by NEASC, CIS and the IB in 2018 and are preparing for a new process focused on learning and learners, beginning in Autumn 2021. The school has undertaken 2 major building projects: and is very proud of the state of the art, multi-purpose building, which includes modern science laboratories, a large auditorium, a gymnasium and a dance studio. In addition, we opened our Track and Football Field, in collaboration with our local neighbour school, in October 2017.`}</p>
    <p>{`A recent, significant and inclusive community project was the identification of PSI’s Core Values: Integrity, Honesty, Trust; Respect, Empathy, Tolerance; Diversity, Equity, Inclusion; Happiness, Joy; Passion. These are fundamental in guiding everything that we do at PSI.`}</p>
    <p>{`An ongoing, primary objective is the promotion of the health, safety and well-being of all our students, staff and families. The precautionary measures in place to help prevent transmission of the Sars-CoV-2 virus have been very effective, and we are proud of our community for their respectful, pragmatic response to these.`}</p>
    <p>{`In addition, a focus on social and emotional well-being is vital. In times like these, when change and uncertainty are constants, a sense of belonging, connectedness and psychological safety is invaluable. Indeed, in the complex study of human psychology, one finding is remarkably consistent: our well-being and ability to strongly perform and to reach our potential rests squarely on our connection to others, on our sense of belonging, on feeling secure, supported, accepted, heard and included. We require emotional, as well as physical safety, if we are to unleash creativity, and create the conditions for cognitive complexity. Emotional and social well-being is a clear indicator of academic achievement, success and satisfaction in later life. The creation of a Diversity, Equity and Inclusion working group has made a strong impact in this regard. This group, consisting of students, staff, faculty and parents has already added a Diversity Statement to PSI’s suite of guiding statements. An audit of all areas of school life has been performed and action plans for curriculum review, our physical environment and community education are in place. We are committed to the ongoing journey to become a model school for global citizenry and genuine inclusion; PSI truly is A Place Where We Belong.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      